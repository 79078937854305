<template>
  <div>

    <b-card>
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          <b-button
            variant="gradient-success"
            class="mr-sm-25"
            size="sm"
            @click="modalCandidate.typeActionModal = 'add', modalCandidate.isOpen = true"
          >
            <feather-icon icon="PlusIcon" /> Add Candidate
          </b-button>
        </b-col>
        <b-col cols="12">
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :pagination-options="{
              enabled: true,
            }"
            :rows="rows"
            :columns="columns"
            :sort-options="{
              enabled: false,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Cito -->
              <span v-if="props.column.field === 'action'">
                <b-button
                  variant="gradient-success"
                  class="btn-icon mr-sm-25"
                  size="sm"
                  @click="preUpdate(props.row)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  variant="gradient-warning"
                  class="btn-icon"
                  size="sm"
                  @click="deleteCandidate(props.row.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>

              </span>
              <span v-else-if="props.column.field === 'photo'">
                <b-button
                  variant="gradient-success"
                  class="btn-icon"
                  size="sm"
                  @click="showModalHtml(imgHtml(props.row.photo), 'Photo')"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </span>
              <span v-else-if="props.column.field === 'misi'">
                <b-button
                  variant="gradient-success"
                  class="btn-icon"
                  size="sm"
                  @click="showModalHtml(props.row.misi, 'Misi')"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </span>
              <span v-else-if="props.column.field === 'visi'">
                <b-button
                  variant="gradient-success"
                  class="btn-icon"
                  size="sm"
                  @click="showModalHtml(props.row.visi, 'Visi')"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </span>
              <span v-else-if="props.column.field === 'riwayat'">
                <b-button
                  variant="gradient-success"
                  class="btn-icon"
                  size="sm"
                  @click="showModalHtml(props.row.riwayat, 'Riwayat')"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </span>
              <span v-else-if="props.column.field === 'narahubung'">
                <b-button
                  variant="gradient-success"
                  class="btn-icon"
                  size="sm"
                  @click="showModalHtml(props.row.narahubung, 'Narahubung')"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>

            <!-- Column: Common -->

            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card>
    <ModalCandidate
      :is-open.sync="modalCandidate.isOpen"
      :action-type="modalCandidate.typeActionModal"
      :auto-fill-form="modalCandidate.dataModalForm"
      @actionDone="loadItems"
    />
    <ModalContentHtml
      :is-open.sync="modalHtml.isOpen"
      :title="modalHtml.title"
      :content="modalHtml.content"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BButton,
} from 'bootstrap-vue'
import { debounce } from 'debounce'
import API from '@/api'
import ModalCandidate from './components/ModalCandidate.vue'
import ModalContentHtml from './components/ModalContentHtml.vue'

export const joinToQuery = (key, value) => {
  if (!value) {
    return ''
  }

  return `${key}=${value}`
}

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    ModalCandidate,
    ModalContentHtml,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: 'Election',
          field: 'election_id',
        },
        {
          label: 'Nama',
          field: 'nama',
        },
        {
          label: 'Angkatan',
          field: 'angkatan',
        },
        {
          label: 'Pendidikan',
          field: 'pendidikan',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Photo',
          field: 'photo',
        },
        {
          label: 'Visi',
          field: 'visi',
        },
        {
          label: 'Misi',
          field: 'misi',
        },
        {
          label: 'Riwayat',
          field: 'riwayat',
        },
        {
          label: 'Narahubung',
          field: 'narahubung',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Created At',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Updated At',
          field: 'updated_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      filters: {
        search: '',
        start_at: '',
        finish_at: '',
      },
      modalHtml: {
        isOpen: false,
        title: '',
        content: '',
      },
      modalCandidate: {
        isOpen: false,
        dataModalForm: null,
        typeActionModal: 'add',
      },
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.onFilters()
      },
    },
  },
  created() {
    this.loadItems()
  },
  methods: {
    imgHtml(url) {
      return `
        <p>pilih-kandidat.png</p>
        <img src="${url}pilih-kandidat.png" alt="pilih-kandidat">
        <p>${url}pilih-kandidat.png</p>
        <br />
        <p>detail-kandidat.png</p>
        <img src="${url}detail-kandidat.png" alt="detail-kandidat">
        <p>${url}detail-kandidat.png</p>
        <br />
        <p>chart.png</p>
        <img src="${url}chart.png" alt="chart">
         <p>${url}chart.png</p>
        <br />
      `
    },
    showModalHtml(content, title) {
      this.modalHtml = {
        isOpen: true,
        title,
        content,
      }
    },
    clearFilter() {
      this.filters = {
        search: '',
        start_at: '',
        finish_at: '',
      }
    },
    // eslint-disable-next-line func-names
    onFilters: debounce(function () {
      this.loadItems()
    }, 200),
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.isLoading = true
        // const { } = this.filters
        const qs = ''
        // qs += joinToQuery('limit', this.serverParams.perPage)
        // qs += joinToQuery('page', this.serverParams.page)
        const { data } = await API.candidate.getCandidate(qs)
        this.totalRecords = data.total
        this.rows = data.data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    preUpdate(candidate) {
      this.modalCandidate = {
        dataModalForm: {
          ...candidate,
        },
        typeActionModal: 'update',
        isOpen: true,
      }
    },
    async deleteCandidate(id) {
      if ((await this.$_customConfirm({ text: 'Apakah anda yakin, ingin menghapus candidate?' }))) {
        try {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
          await API.candidate.deleteCandidate(id)
          this.loadItems()
          this.$_handleMsgSuccess('Berhasil menghapus candidate')
        } catch (error) {
          this.$_handleMsgErrors(error, 'Gagal menghapus candidate')
        } finally {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
        }
      }
    },
  },
}
</script>
