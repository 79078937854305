var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalCandidate",attrs:{"title":((_vm.actionType === 'add' ? 'Tambah' : 'Update') + " candidate"),"ok-title":"Simpan","cancel-title":"Batalkan","no-close-on-backdrop":"","no-close-on-esc":"","size":"lg"},on:{"hide":_vm.closeModal,"ok":_vm.submit}},[_c('ValidationObserver',{ref:"formCandidate",attrs:{"tag":"form"}},[_c('b-form-group',{attrs:{"label":"election","label-for":"election"}},[_c('ValidationProvider',{attrs:{"name":"election","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"form-election-id",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.elections.selectOptions,"reduce":function (election) { return election.id; }},model:{value:(_vm.form.election_id),callback:function ($$v) {_vm.$set(_vm.form, "election_id", $$v)},expression:"form.election_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama","label-for":"nama"}},[_c('ValidationProvider',{attrs:{"name":"nama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"form-nama",attrs:{"id":"nama","placeholder":"nama"},model:{value:(_vm.form.nama),callback:function ($$v) {_vm.$set(_vm.form, "nama", $$v)},expression:"form.nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Angkatan","label-for":"angkatan"}},[_c('ValidationProvider',{attrs:{"name":"angkatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"form-angkatan",attrs:{"id":"angkatan","placeholder":"angkatan","type":"number"},model:{value:(_vm.form.angkatan),callback:function ($$v) {_vm.$set(_vm.form, "angkatan", $$v)},expression:"form.angkatan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Pendidikan","label-for":"pendidikan"}},[_c('ValidationProvider',{attrs:{"name":"pendidikan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"form-pendidikan",attrs:{"id":"pendidikan","placeholder":"pendidikan"},model:{value:(_vm.form.pendidikan),callback:function ($$v) {_vm.$set(_vm.form, "pendidikan", $$v)},expression:"form.pendidikan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Member id","label-for":"member-id"}},[_c('ValidationProvider',{attrs:{"name":"member-id","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"form-member-id",attrs:{"id":"member-id","placeholder":"member id","type":"number"},model:{value:(_vm.form.member_id),callback:function ($$v) {_vm.$set(_vm.form, "member_id", $$v)},expression:"form.member_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"email","label-for":"email"}},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"form-email",attrs:{"id":"email","placeholder":"email","type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"No Urut","label-for":"no-urut"}},[_c('ValidationProvider',{attrs:{"name":"No Urut","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"form-no-urut",attrs:{"id":"no-urut","placeholder":"No urut","type":"number"},model:{value:(_vm.form.no_urut),callback:function ($$v) {_vm.$set(_vm.form, "no_urut", $$v)},expression:"form.no_urut"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Misi","label-for":"misi"}},[_c('ValidationProvider',{attrs:{"name":"Misi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{model:{value:(_vm.form.misi),callback:function ($$v) {_vm.$set(_vm.form, "misi", $$v)},expression:"form.misi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Visi","label-for":"visi"}},[_c('ValidationProvider',{attrs:{"name":"visi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{model:{value:(_vm.form.visi),callback:function ($$v) {_vm.$set(_vm.form, "visi", $$v)},expression:"form.visi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Riwayat","label-for":"riwayat"}},[_c('ValidationProvider',{attrs:{"name":"riwayat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{model:{value:(_vm.form.riwayat),callback:function ($$v) {_vm.$set(_vm.form, "riwayat", $$v)},expression:"form.riwayat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Narahubung / SosMed","label-for":"narahubung"}},[_c('ValidationProvider',{attrs:{"name":"narahubung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{model:{value:(_vm.form.narahubung),callback:function ($$v) {_vm.$set(_vm.form, "narahubung", $$v)},expression:"form.narahubung"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Photo","label-for":"photo"}},[_c('b-form-input',{ref:"form-photo",attrs:{"id":"photo","placeholder":"url photo"},model:{value:(_vm.form.photo),callback:function ($$v) {_vm.$set(_vm.form, "photo", $$v)},expression:"form.photo"}}),_c('small',{staticClass:"text-success"},[_vm._v("example: https://uiconnect.sgp1.digitaloceanspaces.com/candidate-evote/evote-iluni-2022/nomor4/")])],1),_c('b-form-group',{attrs:{"label":"Status","label-for":"status"}},[_c('ValidationProvider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{ref:"form-status",attrs:{"id":"status","options":['0', '1']},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('hr')],1)}
var staticRenderFns = []

export { render, staticRenderFns }