<template>
  <b-modal
    ref="modalCandidate"
    :title="`${actionType === 'add' ? 'Tambah' : 'Update'} candidate`"
    ok-title="Simpan"
    cancel-title="Batalkan"
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
    @hide="closeModal"
    @ok="submit"
  >
    <ValidationObserver
      ref="formCandidate"
      tag="form"
    >

      <b-form-group
        label="election"
        label-for="election"
      >

        <ValidationProvider
          #default="{ errors }"
          name="election"
          rules="required"
        >
          <v-select
            ref="form-election-id"
            v-model="form.election_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="elections.selectOptions"
            :reduce="election => election.id"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="Nama"
        label-for="nama"
      >

        <ValidationProvider
          #default="{ errors }"
          name="nama"
          rules="required"
        >
          <b-form-input
            id="nama"
            ref="form-nama"
            v-model="form.nama"
            placeholder="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>
      <b-form-group
        label="Angkatan"
        label-for="angkatan"
      >

        <ValidationProvider
          #default="{ errors }"
          name="angkatan"
          rules="required"
        >
          <b-form-input
            id="angkatan"
            ref="form-angkatan"
            v-model="form.angkatan"
            placeholder="angkatan"
            type="number"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="Pendidikan"
        label-for="pendidikan"
      >

        <ValidationProvider
          #default="{ errors }"
          name="pendidikan"
          rules="required"
        >
          <b-form-input
            id="pendidikan"
            ref="form-pendidikan"
            v-model="form.pendidikan"
            placeholder="pendidikan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="Member id"
        label-for="member-id"
      >

        <ValidationProvider
          #default="{ errors }"
          name="member-id"
          rules=""
        >
          <b-form-input
            id="member-id"
            ref="form-member-id"
            v-model="form.member_id"
            placeholder="member id"
            type="number"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="email"
        label-for="email"
      >

        <ValidationProvider
          #default="{ errors }"
          name="email"
          rules="required"
        >
          <b-form-input
            id="email"
            ref="form-email"
            v-model="form.email"
            placeholder="email"
            type="email"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="No Urut"
        label-for="no-urut"
      >

        <ValidationProvider
          #default="{ errors }"
          name="No Urut"
          rules="required"
        >
          <b-form-input
            id="no-urut"
            ref="form-no-urut"
            v-model="form.no_urut"
            placeholder="No urut"
            type="number"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="Misi"
        label-for="misi"
      >

        <ValidationProvider
          #default="{ errors }"
          name="Misi"
          rules="required"
        >
          <quill-editor
            v-model="form.misi"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="Visi"
        label-for="visi"
      >

        <ValidationProvider
          #default="{ errors }"
          name="visi"
          rules="required"
        >
          <quill-editor
            v-model="form.visi"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="Riwayat"
        label-for="riwayat"
      >

        <ValidationProvider
          #default="{ errors }"
          name="riwayat"
          rules="required"
        >
          <quill-editor
            v-model="form.riwayat"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="Narahubung / SosMed"
        label-for="narahubung"
      >

        <ValidationProvider
          #default="{ errors }"
          name="narahubung"
          rules="required"
        >
          <quill-editor
            v-model="form.narahubung"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="Photo"
        label-for="photo"
      >

        <b-form-input
          id="photo"
          ref="form-photo"
          v-model="form.photo"
          placeholder="url photo"
        />

        <small class="text-success">example: https://uiconnect.sgp1.digitaloceanspaces.com/candidate-evote/evote-iluni-2022/nomor4/</small>
      </b-form-group>

      <b-form-group
        label="Status"
        label-for="status"
      >

        <ValidationProvider
          #default="{ errors }"
          name="status"
          rules="required"
        >
          <b-form-select
            id="status"
            ref="form-status"
            v-model="form.status"
            :options="['0', '1']"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>
    </ValidationObserver>
    <hr>
  </b-modal>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import API from '@/api'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

const formObj = {
  // id: null,
  election_id: null,
  nama: null,
  angkatan: null,
  pendidikan: null,
  member_id: null,
  email: null,
  no_urut: null,
  visi: null,
  misi: null,
  riwayat: null,
  narahubung: null,
  status: null,
  photo: null,
  photo_720: null,
  photo_24: null,
  photo_154: null,
}

export default {

  components: {
    vSelect,
    quillEditor,
    BFormSelect,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    actionType: {
      type: String,
      default: 'add',
      validator(value) {
        // The value must match one of these strings
        return ['add', 'update'].indexOf(value) !== -1
      },
    },

    autoFillForm: {
      type: Object,
      default: () => ({ ...formObj }),
    },

  },

  data() {
    return {
      form: { ...formObj },
      elections: {
        data: [],
        selectOptions: [],
        fetch: 'idle',
      },
      required,
    }
  },
  computed: {
    isActionUpdate() {
      return this.actionType === 'update'
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.form = { ...formObj }
        if (this.actionType === 'update') {
          this.form = this.autoFillForm
        }
        this.$refs.modalCandidate.show()
      }
    },
  },
  created() {
    this.fetchElections()
  },
  mounted() {
    // this.$refs.modalCandidate.show()
  },
  methods: {
    closeModal() {
      this.$emit('update:is-open', false)
    },

    resetForm() {
      this.form = { ...formObj }
    },

    uploadImage(e, key) {
      const { files } = e.target
      // eslint-disable-next-line prefer-destructuring
      this.form[key] = files[0]
    },

    async add() {
      if ((await this.$_customConfirm({ text: 'Apakah anda yakin, ingin menambahkan candidate?' }))) {
        try {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
          const formData = new FormData()

          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const key in this.form) {
            if (this.form[key] !== null) {
              formData.append(key, this.form[key])
            }
          }

          await API.candidate.createCandidate(formData)
          this.$emit('actionDone')
          this.$refs.modalCandidate.hide()
          this.resetForm()
          this.$_handleMsgSuccess('Berhasil menambahkan candidate')
        } catch (error) {
          console.log(error)
          this.$_handleMsgErrors(error, 'Gagal menambahkan candidate')
        } finally {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
        }
      }
    },

    async update() {
      if ((await this.$_customConfirm({ text: 'Apakah anda yakin, ingin menyimpan perubahan?' }))) {
        try {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
          const formData = new FormData()

          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const key in this.form) {
            if (this.form[key] !== null) {
              formData.append(key, this.form[key])
            }
          }

          await API.candidate.updateCandidate(formData)
          this.$emit('actionDone')
          this.$refs.modalCandidate.hide()
          this.resetForm()
          this.$_handleMsgSuccess('Berhasil menyimpan perubahan candidate')
        } catch (error) {
          console.log(error)
          this.$_handleMsgErrors(error, 'Gagal menyimpan perubahan candidate')
        } finally {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
        }
      }
    },

    async submit(e) {
      e.preventDefault()
      if (await this.$_checkValidateOfForm('formCandidate')) {
        this[this.actionType]()
      }
    },

    async fetchElections() {
      try {
        this.elections.fetching = 'pending'
        const { data } = await API.election.getElection('limit=50')
        this.elections.data = data
        this.elections.selectOptions = data.data
        this.elections.fetching = 'resolved'
      } catch (error) {
        console.log(error)
        this.elections.fetching = 'rejected'
      }
    },

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
