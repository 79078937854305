<template>
  <b-modal
    ref="modalContentHtml"
    :title="title"
    size="lg"
    hide-footer
    centered
    @hide="closeModal"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-html="content" />
  </b-modal>
</template>

<script>
export default {

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$refs.modalContentHtml.show()
      }
    },
  },
  mounted() {
    // this.$refs.modalContentHtml.show()
  },
  methods: {
    closeModal() {
      this.$emit('update:is-open', false)
    },

  },

}
</script>
